import imagesLoaded from 'imagesloaded'
import React, { useState, Fragment, useContext } from 'react'
import Modal from 'react-modal';

// components
import MediaModal from './components/MediaModal'
import GlossaryPage from './pages/GlossaryPage'
import Intro from './pages/Intro'
import SelectPage from './pages/SelectPage'
import SlidesPage from './pages/SlidesPage'

// functionality
import { fadeIn, fadeOut } from './animations/pageAnimation'
import ModalContext from './Context/modal'
import useAppMeta from './hooks/useAppMeta'
import useFetch from './hooks/useFetch'
import useGoogleAnalytics from './hooks/useGoogleAnalytics'
import useInitRoute from './hooks/useInitRoute'

Modal.setAppElement('#root');

const App = ({ route }) => {
  const rootElem = document.querySelector('#root')
  const [step, setStep] = useState(null)
  const [personData, setPersonData] = useState([])
  const { showMediaModal } = useContext(ModalContext)
  useGoogleAnalytics()

  // prep loading
  const [isLoading, setIsLoading] = useState(true)
  const [pageReady, setPageReady] = useState(false)

  // slides animation
  const [slideIsAnimating, setSlideIsAnimating] = useState(true)
  const [introIsAnimating, setIntroIsAnimating] = useState(true)

  const readyToFadeIn = () => {
    const begin = () => setIsLoading(false)
    const complete = () => setPageReady(true)
    fadeIn(begin, complete)
  }

  const goToStep = (step) => {
    const complete = () => {
      setPageReady(false)
      setStep('') // unset for state update - helpful to just re-init same step
      setStep(step)
      setIsLoading(true)
      imagesLoaded('.page', () => {
        readyToFadeIn()
      })
    }

    fadeOut(complete)
  }

  // custom hooks
  const { data } = useFetch(rootElem.getAttribute('data-api-url'))
  const { appMeta } = useAppMeta(data)
  useInitRoute(data, setPersonData, goToStep)

  if (!data) {
    return (
      <div className="loadingApp">
        <span>Loading...</span>
      </div>
    )
  }

  return (
    <Fragment>
      {isLoading ? (
        <div className="loadingApp">
          <span>Loading...</span>
        </div>
      ) : null}

      <div className="loadScreen"></div>

      <div className={`App ${showMediaModal ? 'modalOpen' : ''}`}>
        {step === 'intro' ? (
          <Intro
            data={data}
            goToSelect={() => goToStep('personSelect')}
            introIsAnimating={introIsAnimating}
            setIntroIsAnimating={setIntroIsAnimating}
          />
        ) : null}

        {step === 'glossary' ? (
          <GlossaryPage glossaryData={data.glossary.data} glossaryPdf={data.glossary_pdf} />
        ) : null}

        {step === 'personSelect' ? (
          <SelectPage
            appMeta={appMeta}
            stories={data.stories.data}
            personData={personData}
            setPersonData={setPersonData}
            goToSlides={() => goToStep('slides')}
          />
        ) : null}

        {step === 'slides' ? (
          <SlidesPage
            appMeta={appMeta}
            timelineData={data.maptimelines.data}
            pageReady={pageReady}
            personData={personData}
            slides={personData.slides.data}
            goToStep={goToStep}
            slideIsAnimating={slideIsAnimating}
            setSlideIsAnimating={setSlideIsAnimating}
          />
        ) : null}
      </div>

      <MediaModal detailPages={data.detailPages.data} />
    </Fragment>
  )
}

export default App
