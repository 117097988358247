import { Button, GenericModal } from 'moar-interactive-shared'
import React, { useEffect, useLayoutEffect, useRef, useState, Fragment } from 'react'
import { Untabbable } from 'react-untabbable'

import AudioPlayer from './AudioPlayer'
import { slideOut, fadeOut } from './../animations/modalAnimations'
import slideAnimation from './../animations/slideAnimation';
import useWindowSize from '../hooks/useWindowSize'
import isMobile from '../utils/isMobile'

const Slide = ({
  isActive,
  choices = [],
  nextSlide,
  setSlideIsAnimating,
  question,
  audio,
  hotspots,
  image_alt_text,
  image_position,
  mobile_hotspots,
  pageReady,
}) => {
  //------------------------------
  // State/Hooks
  //------------------------------

  const [timeline, setTimeline] = useState()
  const [hasAnimated, setHasAnimated] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const textRef = useRef();
  const imageRef = useRef();
  const hotspotRef = useRef();
  const windowSize = useWindowSize();

  //------------------------------
  // Effects
  //------------------------------

  useEffect(() => {
    setSlideIsAnimating(false)
  }, [isActive]) /* eslint-disable-line */

  useLayoutEffect(() => {
    if (isActive === true && hasAnimated === false) {
      setTimeline(
        slideAnimation(
          textRef,
          imageRef,
          hotspotRef,
          onAnimationBegin,
          onAnimationEnd,
          windowSize.width,
        ),
      )
    }
  }, [isActive]) /* eslint-disable-line */

  // trigger animation when active and timeline ready
  // and had not animated yet
  useEffect(() => {
    if (isActive && timeline && !hasAnimated && pageReady) {
      timeline.play()
    }
  }, [isActive, timeline, hasAnimated, pageReady])

  //------------------------------
  // Helpers
  //------------------------------

  const onAnimationBegin = () => {
    setSlideIsAnimating(true)
  }

  const onAnimationEnd = () => {
    setSlideIsAnimating(false)
    setHasAnimated(true)
  }

  const nextPanel = () => {
    const modal = document.querySelector('.optionModal .modal')

    nextSlide()
    const modalObj = { current: modal }
    slideOut(modalObj, () => setShowModal(false))
  }

  const closeModal = () => {
    const modal = document.querySelector('.optionModal .modal')

    // setTimeout(() => {
    const modalObj = { current: modal }
    fadeOut(modalObj, () => setShowModal(false))
  }

  //------------------------------
  // Render
  //------------------------------

  const classArray = () => {
    const classArray = []

    if (isActive) {
      classArray.push('slide--active')
    }

    if (image_position === 'right') {
      classArray.push('slide--invert')
    }

    return classArray.join(' ')
  }

  const generateOption = (data) => {
    if (!data) return
    return (
      <Fragment>
        <div className="modal__content">
          <div className="selection">
            <h2 className="selection__title">{data.answer_title}</h2>
            <div className="selection__text">
              <p>{data.answer_text}</p>
            </div>

            {'audio' in data && data.audio && (
              <div className="selection__audio">
                <AudioPlayer audioFile={data.audio} />
              </div>
            )}
          </div>

          <div className="optionButtons">
            <Button
              iconPosition="before"
              darkBg={true}
              label="Back"
              handleClick={() => closeModal()}
            />

            <Button
              darkBg={true}
              label="Continue Story"
              handleClick={() => nextPanel()}
            />
          </div>
        </div>
      </Fragment>
    )
  }

  let hotspotImage

  if (isMobile()) {
    hotspotImage = mobile_hotspots.image
  } else {
    hotspotImage = hotspots.image
  }

  return (
    <div className={`slide slide--options ${classArray()}`}>
      <Untabbable disabled={isActive}>
        <div className="slide__content">
          <div className="slide__image">
            <div className="content imageContent">
              <div className="slide__hotspots">
                <div className="content" ref={hotspotRef} />
              </div>

              <div className="image" ref={imageRef}>
                <img
                  className="desktopImage"
                  alt={image_alt_text}
                  src={hotspotImage}
                />
              </div>
            </div>
          </div>

          <div className="slide__text" ref={textRef}>
            <h2>{question}</h2>

            <div className="options">
              {choices.map((choice, index) => {
                return (
                  <div className="option" key={index}>
                    <p>{choice.summary}</p>
                    <Button
                      basic={true}
                      label={choice.cta_text}
                      handleClick={() => {
                        setModalData(choice)
                        setShowModal(true)
                      }}
                    />
                  </div>
                )
              })}
            </div>

            {audio && (
              <div className="slide__audio">
                <AudioPlayer audioFile={audio} />
              </div>
            )}
          </div>
        </div>
      </Untabbable>

      <GenericModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        overlayName={'optionModal'}
      >
        {generateOption(modalData)}
      </GenericModal>
    </div>
  )
}

export default Slide
